import React from 'react';
import Layout from 'components/layout';
import {Link} from "gatsby";
import SeoComponent from 'components/seo';
import { Card, Hero, Breadcrumb } from "components/ui";
import HeroImage from "assets/images/hero-default.jpg";
import Tutorials from "assets/images/tutorials.jpg";
import VideosandWebinars from "assets/images/videos-and-webinars.jpg";
import ChildWelfareTrainingToolkit from "assets/images/child-welfare-training-toolkit.jpg";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined";

const trainingCardData = [{title:"Tutorials", link:"https://ncsacw.acf.hhs.gov/tutorials/Default.aspx"}, {title:"Child Welfare Training Toolkit", link:"toolkit"}, {title:"Videos and Webinars", link:"videos-and-webinars"}];

const TrainingLandingPage = ({ location }) => {

    //let thisLocation = '/'
    let thisLocationPathname = ''

    if (isBrowser) {
        //thisLocation = window.location
        thisLocationPathname = window.location.pathname
    }
    return (
        <Layout location={location}>
            <SeoComponent title="Training" description="NCSACW provides training resources to help professionals increase their knowledge and skills to work with families affected by substance use disorders and effectively collaborate with the agencies serving these families." />
            <div className="margin-bottom-5">
                <Hero
                img={HeroImage}
                color="#f5f5f5"
                >
                    <Breadcrumb pathname={thisLocationPathname} />
                    <h1 name="main-content" id="main-content" className="usa-hero__heading" aria-label="Hero: Training">Training</h1>
                    <p>NCSACW provides training resources to help professionals increase their knowledge and skills to work with families affected by substance use disorders and effectively collaborate with the agencies serving these families.</p>
                </Hero>
            </div>
            <div className="grid-container padding-bottom-5">
                <div className="usa-card-group">
                    {trainingCardData.map((training,idx)=>{
                        return (
                            <Link to={training.link} className="desktop:grid-col-4 tablet:grid-col-6 margin-bottom-2 text-no-underline" key={`training-${idx+1}`}>
                                <Card grid="width-full height-full">
                                    <Card.Header><h2 className="usa-card__heading green">{training.title}</h2></Card.Header>
                                    <Card.Media>
                                        <div className="usa-card__img">
                                            {(() => {
                                            switch(training.title) {
                                                case "Tutorials":
                                                    return <img src={Tutorials} alt="" />
                                                case "Child Welfare Training Toolkit":
                                                    return <img src={ChildWelfareTrainingToolkit} alt="" />
                                                case "Videos and Webinars":
                                                    return <img src={VideosandWebinars} alt="" />
                                                default:
                                                    return <img src={HeroImage} alt=""/>
                                            }})()}
                                        </div>
                                    </Card.Media>
                                </Card>
                            </Link>
                        )
                    })}
                </div>
            </div>
        </Layout>
    )};

export default TrainingLandingPage;
